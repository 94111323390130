import { useState } from 'react';
import './style.css';
import { createCompetition } from '../../services/AWS/competitionInformation/competitionInfo';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { MastereventList } from '../../ultis/masterData';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
const userAuthtoken = localStorage.getItem('MYCAAdminToken');
function CreateCompetitionForm() {
    const handleDateChange = (newValue) => {
        setSelectedDate((dayjs(newValue).second(0).unix()));
    }
    const handleCloseDateChange = (newValue) => {
        setSelectedCloseDate((dayjs(newValue).second(0).unix()));
    }
    const shouldDisableTime=(value, view) =>{return (
        view === 'minutes' && value.minute() >= 1)
    }
  const eventList = MastereventList;
    const [SelectedDate,setSelectedDate] = useState();
    const [selectedCloseDate,setSelectedCloseDate] = useState();
  const [competitionData, setCompetitionData] = useState({
    Competition_name: '',
    Competition_date: '',
    Venue: '',
    Address: '',
    address_details: '',
    contact_email: '',
    Organizers: '',
    wca_delegates: '',
    created_id: '',
    competition_details: 'none',
    ActiveStatus: 'Active',
    Events: '',
    Events_price: '',
    Base_charges: '',
    Raw_Base_charges: '',
    Raw_Admin_charges: '',
    Raw_Guest_charges: '',
    Participant_limit: '',
    WaitingList_limit: '',
    Auto_approve:true,
    allowPostApprovalEdit: false,
    wcaURL: '',
    enforceUnderAgeGuestTicket: false
  });
  let InitialListMap = new Map();
  //map eventList to InitalMap with false value
  for (let i = 0; i < eventList.length; i++) {
    InitialListMap.set(eventList[i], false);
  }
  let InitialPricemap = new Map();
  for (let i = 0; i < eventList.length; i++) {
    InitialPricemap.set(eventList[i], 0);
  }
  const [EventToParticipate, setEventToParticipate] = useState(InitialListMap);
  const [eventPrice, setEventPrice] = useState(InitialPricemap);
  const [errorMessages, setErrorMessages] = useState({});
    const [qualificationTime, setQualificationTime] = useState({});
    const [qualificationType, setQualificationType] = useState({});
    const [qualificationMethod, setQualificationMethod] = useState({});
  const dispatch = useDispatch();
  const renderErrorMessage = (name) =>
  name === errorMessages.name && (
    <div className="error">{errorMessages.message}</div>
  );
// initate all selected event with false value

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCompetitionData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
    const handleCheckBoxClick = (event) => {
        const { name, value } = event.target;
        setCompetitionData((prevData) => ({
            ...prevData,
            [name]: competitionData[name] ? false : true
        }));
    };
  

  const handleSubmit =async (event) => {
    event.preventDefault();
    dispatch({ type: 'loading' });
    // submit the data to your backend or do something else with it
   const validateInputResult = await validateInput(competitionData);
   const validateEventResult = await validateSelectedEvent( EventToParticipate,eventPrice);
    if (!validateInputResult || !validateEventResult) {
      dispatch({ type: 'loaded' });
    return;
   }
   const competitionDataToInsert = competitionData;
    competitionDataToInsert.Events = await saveSelectedEventsToString(EventToParticipate);
    competitionDataToInsert.Events_price = await saveSelectedEventsPriceToString(eventPrice, EventToParticipate);
    competitionDataToInsert.registrationOpenTime = SelectedDate;
    competitionDataToInsert.registrationCloseTime = selectedCloseDate;
    // append BaseFee:
    competitionDataToInsert.Base_charges = `Base_charges:${competitionDataToInsert.Raw_Base_charges}`;
    //append admin Charges
      competitionDataToInsert.Base_charges = competitionDataToInsert.Base_charges + `;Admin_charges:${competitionDataToInsert.Raw_Admin_charges}` + `;Guest_charges:${competitionDataToInsert.Raw_Guest_charges}`
      let qualificationToInsert = null
      //se to example of 3x3x3:average:100;4x4x4:single:9000
//loop eventlist and check in qualification time
      for (let i = 0; i < eventList.length; i++) {
          if (EventToParticipate[eventList[i]] === true) {
              if (qualificationTime[eventList[i]]) {
                  if (!qualificationType[eventList[i]]) {
                      qualificationType[eventList[i]] = "single";
                  }
                  if (!qualificationMethod[eventList[i]]) {
                        qualificationMethod[eventList[i]] = "score";
                  }
                  if (Number(qualificationTime[eventList[i]]) !== 0) {
                      if (qualificationToInsert == null) {
                          qualificationToInsert = eventList[i] + ":" + qualificationType[eventList[i]] + ":"  + qualificationMethod[eventList[i]] + ":" + qualificationTime[eventList[i]] + ";"
                      }
                      else {
                          qualificationToInsert = eventList[i] + ":" + qualificationType[eventList[i]] + ":"  + qualificationMethod[eventList[i]] + ":" + qualificationTime[eventList[i]] + ";"
                      }

                  }

              }
          }
      }
      competitionDataToInsert.qualification = qualificationToInsert;
    const Apirespond = await createCompetition(competitionDataToInsert,userAuthtoken);
    if (Apirespond.data != "Competition created successfully!") {
      setErrorMessages({name:"General", message:Apirespond.data});
    }
    else{
      setErrorMessages({name:"General", message:"Competition created successfully!"});
    }
    dispatch({ type: 'loaded' });
  };

const saveSelectedEventsToString = (EventToParticipate) => {
    let selectedEvents = "";

    for (const entry of eventList) {
      let key = entry;
     let value = EventToParticipate[entry];
      if (value == true) {
        selectedEvents += key + ",";
      }
    }
        // remove the last comma if the last char is comma
    if (selectedEvents.slice(-1) === ",")
    {
      selectedEvents = selectedEvents.slice(0, -1);
    }
    return selectedEvents;
  }
  const saveSelectedEventsPriceToString = (eventPrice,EventToParticipate) => {
    let selectedEventsPrice = "";
    for (const entry of eventList) {
      let key = entry;
     let value = EventToParticipate[entry];
      if (value == true) {
        selectedEventsPrice += key + ":" + eventPrice[entry] + ",";
      }
    }
    // remove the last comma if the last char is comma
    if (selectedEventsPrice.slice(-1) === ",")
    {
      selectedEventsPrice = selectedEventsPrice.slice(0, -1);
    }


    return selectedEventsPrice;
  }

  const validateSelectedEvent = (EventToParticipate,eventPrice) => {
    let haveEvent = false;


        for (const entry of eventList) {
          let key = entry;
         let value = EventToParticipate[entry];
          if (value == true) {
            haveEvent = true;
          }

        }
        if (!haveEvent) {
          setErrorMessages({name:"General", message:"Please select at least one event"});
          return false;
        }
    if (eventPrice.size === 0 && EventToParticipate.size != 0) {
      setErrorMessages({name:"General", message:"Please enter price for each event"});
      return false;
    }
    // for all event in EventToParticipate, event price must have a number value
    for (const entry of eventList) {


      let key = entry;
     let value = EventToParticipate[entry];
      if (value == false || value == undefined){
        continue;
      }
      if (!eventPrice[key]) {
        setErrorMessages({name:"General", message:"Please enter price for  event " + key });
        return false;
      }
      else if (isNaN(eventPrice[key])) {
        setErrorMessages({name:"General", message:"Price must be a number"});
        return false;
      }
    }
    return true;
  }
const validateInput = (data) => {
  if (!data.Competition_name) {
    setErrorMessages({name:"General", message:"Competition Name is required"});
    return false;
  }
  if (!data.Competition_date) {
    setErrorMessages({name:"General", message:"Competition Date is required"});
    return false;
  }
  if (!data.Venue) {
    setErrorMessages({name:"General", message:"Venue is required"});
    return false;
  }
  if (!data.Address) {
    setErrorMessages({name:"General", message:"Address is required"});
    return false;
  }
  if (!data.address_details) {
    setErrorMessages({name:"General", message:"Address Details is required"});
    return false;
  }
  if (!data.contact_email) {
    setErrorMessages({name:"General", message:"Contact Email is required"});
    return false;
  }
  if (!data.Organizers) {
    setErrorMessages({name:"General", message:"Organizers is required"});
    return false;
  }
  if (!data.wca_delegates) {
    setErrorMessages({name:"General", message:"WCA Delegates is required"});
    return false;
  }
  if (!data.competition_details) {
    setErrorMessages({name:"General", message:"Competition Details is required"});
    return false;
  }
    if (!SelectedDate) {
        setErrorMessages({name:"General", message:"Registration Open Date is required"});
        return false;
    }
    if (!selectedCloseDate) {
        setErrorMessages({name:"General", message:"Registration Close Date is required"});
        return false;
    }
  if (!data.ActiveStatus) {
    setErrorMessages({name:"General", message:"Active Status is required"});
    return false;
  }

  if (!data.Raw_Base_charges) {
    setErrorMessages({name:"General", message:"Base Charges is required"});
    return false;
  }
    if (!data.Raw_Admin_charges) {
        setErrorMessages({name:"General", message:"Admin Charges is required"});
        return false;
    }
    if (!data.Raw_Guest_charges) {
        setErrorMessages({name:"General", message:"Guest Charges is required"});
        return false;
    }
  if (!data.Participant_limit) {
    setErrorMessages({name:"General", message:"Participant Limit is required"});
    return false;
  }
  if (Number(data.Participant_limit) > Number(data.WaitingList_limit)) {
    setErrorMessages({ name: "General", message: "Participant limit should be less than waiting list limit" });
    return false;
  }
return true;

}
  


  const handleEventsCheckboxChange = (event) =>{
    setEventToParticipate((prevData) => ({
      ...prevData,
      [event.target.name]: !EventToParticipate[event.target.name]
    }));

 
  }
    const handleEventQualificationTimeChange = (event) => {
        const { name, value } = event.target;


        setQualificationTime((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }
    const handleEventQualificationTypeChange = (event) => {
        const { name, value } = event.target;
        setQualificationType((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }
    const handleEventQualificationMethodChange = (event) => {
        const { name, value } = event.target;
        setQualificationMethod((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }
    const displayQualificationTime = (event) => {
        if (!qualificationTime.hasOwnProperty(event)|| qualificationTime[event] === null) {
            return 0;
        }
        return qualificationTime[event];

    }

    const displayQualificationType = (event) => {
        if (!qualificationType.hasOwnProperty(event) || qualificationType[event] === null) {
            return "single";

        }
        return qualificationType[event];
    }
    const displayQualificationMethod = (event) => {
        if (!qualificationMethod.hasOwnProperty(event) || qualificationMethod[event] === null) {
            return "score";

        }
        return qualificationMethod[event];
    }
  const handlePriceChange = (event) => {
    const { name, value } = event.target;
    // setEventPrice
    setEventPrice((prevData) => ({
      ...prevData,
      [name]: value
    }));

  };
  return (
    <div className="register-form">
      <h2>New Competition</h2>
        <form onSubmit={handleSubmit}>
            <div className="input-container">
                <label>
                    Competition Name: </label>
                <input
                    type="text"
                    name="Competition_name"
                    value={competitionData.Competition_name}
                    onChange={handleInputChange}
                />

            </div>
            <div className="input-container">
                <label>
                    Competition Date: </label>
                <input
                    type="text"
                    name="Competition_date"
                    value={competitionData.Competition_date}
                    onChange={handleInputChange}
                />

            </div>
            <div className="input-container">
                <label>
                    Venue: </label>
                <input
                    type="text"
                    name="Venue"
                    value={competitionData.Venue}
                    onChange={handleInputChange}
                />

            </div>
            <div className="input-container">
                <label>
                    Address: </label>
                <input
                    type="text"
                    name="Address"
                    value={competitionData.Address}
                    onChange={handleInputChange}
                />

            </div>
            <div className="input-container">
                <label>
                    Address Details: </label>
                <input
                    type="text"
                    name="address_details"
                    value={competitionData.address_details}
                    onChange={handleInputChange}
                />

            </div>
            <div className="input-container">
                <label>
                    Contact Email: </label>
                <input
                    type="email"
                    name="contact_email"
                    value={competitionData.contact_email}
                    onChange={handleInputChange}
                />

            </div>
            <div className="input-container">
                <label>
                    Organizers: </label>
                <input
                    type="text"
                    name="Organizers"
                    value={competitionData.Organizers}
                    onChange={handleInputChange}
                />

            </div>
            <div className="input-container">
                <label>
                    WCA Delegates: </label>
                <input
                    type="text"
                    name="wca_delegates"
                    value={competitionData.wca_delegates}
                    onChange={handleInputChange}
                />

            </div>
            <div className="input-container">
                <label>Registration Open Date (in UTC +8 time. Competition Active must be set to true for auto open
                    registration to work)* </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDateTimePicker shouldDisableTime={shouldDisableTime} disablePast
                                           onChange={(newValue) => handleDateChange(newValue)}
                                           defaultValue={dayjs(moment().format('YYYY-MM-DD'))}/>
                </LocalizationProvider>
            </div>
            <div className="input-container">
                <label>Registration Close Date (in UTC +8 time. Competition Active must be set to true for auto open
                    registration to work)* </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDateTimePicker shouldDisableTime={shouldDisableTime} disablePast
                                           onChange={(newValue) => handleCloseDateChange(newValue)}
                                           defaultValue={dayjs(moment().format('YYYY-MM-DD'))}/>
                </LocalizationProvider>
            </div>
            <div className="input-container">
                <label>
                    Active Status (Visible on website): </label>
                <select value={competitionData.ActiveStatus} onChange={handleInputChange} name="ActiveStatus">
                    <option value="Active">Active</option>

                    <option value="Inactive">Inactive</option>

                </select>
            </div>
            <div>
                <input type="checkbox" checked={competitionData.Auto_approve} onClick={handleCheckBoxClick}
                       name="Auto_approve"/>
                Auto Approve after competitor make payment

            </div>
            <div>
                <input type="checkbox" checked={competitionData.allowPostApprovalEdit} onClick={handleCheckBoxClick}
                       name="allowPostApprovalEdit"/>
                Allow participant edit registration after approval without extra charges

            </div>
            <div className="input-container">
                <label>
                    Participant Limit:</label>
                <input
                    type="text"
                    pattern="[0-9]*"
                    name="Participant_limit"
                    value={competitionData.Participant_limit}
                    onChange={handleInputChange}
                />

            </div>
            <div className="input-container">
                <label>
                    Participant including Waiting List Limit: </label>
                <input
                    type="text"
                    pattern="[0-9]*"
                    name="WaitingList_limit"
                    value={competitionData.WaitingList_limit}
                    onChange={handleInputChange}
                />

            </div>
            <div className="input-container">
                <label>
                    Base Charge:</label>
                <input
                    type="text"
                    pattern="[0-9]*"
                    name="Raw_Base_charges"
                    value={competitionData.Raw_Base_charges}
                    onChange={handleInputChange}
                />

            </div>
            <div className="input-container">
                <label>
                    Admin Charge:</label>
                <input
                    type="text"
                    pattern="[0-9]*"
                    name="Raw_Admin_charges"
                    value={competitionData.Raw_Admin_charges}
                    onChange={handleInputChange}
                />
            </div>
            <div className="input-container">
                <label>
                    Per Guest Charge:</label>
                <input
                    type="text"
                    pattern="[0-9]*"
                    name="Raw_Guest_charges"
                    value={competitionData.Raw_Guest_charges}
                    onChange={handleInputChange}
                />
            </div>
            <div className="input-container">
                <label>
                    WCA Url:</label>
                <small>do not include "https" and "www" (e.g.:
                    worldcubeassociation.org/competitions/PleaseBeQuietBukitJalil2023)</small>
                <input
                    type="text"
                    name="wcaURL"
                    value={competitionData.wcaURL}
                    onChange={handleInputChange}
                />

            </div>
            <div className="input-container">
                <label>
                    Events: </label>
                <h4>leave qualification time to 0 if not setting up qualification for that event</h4>
                <h4>The qualification condifition will be less then the value set, greater than for multi blind
                    event</h4>
                <h4>The default qualification type is single, please check before submit</h4>
                {eventList.map((item) => (
                    <EventListSelection event={item} EventToParticipate={EventToParticipate}
                                        handleEventsCheckboxChange={(event) => {
                                            handleEventsCheckboxChange(event)
                                        }}
                                        handlePriceChange={(event) => {
                                            handlePriceChange(event)
                                        }}
                                        handleEventQualificationTimeChange={(event) => {
                                            handleEventQualificationTimeChange(event)
                                        }}
                                        handleEventQualificationTypeChange={(event) => {
                                            handleEventQualificationTypeChange(event)
                                        }}
                                        handleEventQualificationMethodChange={(event) => {
                                            handleEventQualificationMethodChange(event)
                                        }}
                                        eventPrice={eventPrice}
                                        displayQualificationTime={displayQualificationTime}
                                        displayQualificationType={displayQualificationType}
                                        displayQualificationMethod={displayQualificationMethod}

                    />
                ))}
            </div>
            {renderErrorMessage("General")}

            <div className="button-container">
                <input type="submit"/>
            </div>
        </form>
    </div>
  );
}

const EventListSelection = (props) => {
    //
    return (
        <div>
            <div>
                <input type="checkbox" name={props.event}
                       checked={props.EventToParticipate[props.event]} value={props.EventToParticipate[props.event]}
                       onChange={props.handleEventsCheckboxChange}/>
                {props.event}
            </div>
            <div>
                <label>
                    Price:
                </label>
                <input style={{marginLeft: "5px", marginRight: "5px", width: "50px"}} key={props.event} pattern="[0-9]*"
                       type="text" name={props.event}
                       value={props.eventPrice[props.event]} onChange={props.handlePriceChange}/>

                {props.EventToParticipate[props.event] &&
                    <div style={{marginLeft: "5px", marginRight: "5px", marginTop: "5px", marginBottom: "10px"}}><label
                        style={{marginLeft: "5px", marginRight: "5px"}}>
                        Qualification Type:
                    </label>
                        <select style={{marginLeft: "5px", marginRight: "5px", marginTop: "5px"}}
                                value={props.displayQualificationType(props.event)}
                                onChange={props.handleEventQualificationTypeChange}
                                name={props.event}>
                            <option value="single">single</option>
                            <option value="average">average</option>

                        </select>
                        <label style={{marginLeft: "5px", marginRight: "5px"}}>
                            Qualification method:
                        </label>
                        <select style={{marginLeft: "5px", marginRight: "5px", marginTop: "5px"}}
                                value={props.displayQualificationMethod(props.event)}
                                onChange={props.handleEventQualificationMethodChange}
                                name={props.event}>
                            <option value="ranking">World Ranking</option>
                            <option value="score">Time/Score</option>

                        </select>
                        <label style={{marginLeft: "5px", marginRight: "5px"}}>
                            Value (seconds*100,score for fmc and mlb):
                        </label>
                        <input style={{marginLeft: "5px", marginRight: "5px", width: "50px"}}
                               key={props.event}
                               pattern="[0-9]*"
                               type="text"
                               name={props.event}
                               value={props.displayQualificationTime(props.event)}
                               onChange={props.handleEventQualificationTimeChange}/></div>}
            </div>
        </div>
    )
}

export default CreateCompetitionForm;
